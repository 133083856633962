import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";

import SEO from "../../../components/seo";

// SVG
import Logo from "../../../images/sparkrise-logo-vbalance.svg";

// Cookie Consent - Tutorial https://www.improvebadcode.com/gatsby-gdpr-cookie-consent/
// https://www.gatsbyjs.com/plugins/gatsby-plugin-gdpr-cookies/#initialize-and-track
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

// CSS
import '../../../styles/campaigns/topgun.css';

// markup
const ActionPage = () => {
    const { imageData } = useStaticQuery(
        graphql`
          query {
            imageData: file(relativePath: { eq: "John-Becerra-Val-Kilmer-Brad-Koepenick.jpeg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `
    );

    return (
        <div className="flex flex-col min-h-screen font-sans bg-light-gray">
            <main className="bg-white">
                <CookieConsent
                    location="top"
                    buttonText="Click to Accept"
                    cookieName="sparkrise-google-analytics-consent"
                    onAccept={() => {
                        Cookies.set("sparkrise-facebook-pixel-consent", "true", { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), sameSite: 'lax' });
                        initializeAndTrack(useLocation);
                    }}
                    style={{ justifyContent: "center" }}
                    contentStyle={{ flex: "0 0 100%", textAlign: "center" }}
                    buttonStyle={{
                        marginTop: "0px"
                    }}
                // overlay
                // overlayStyle={{
                //     position: "fixed",
                //     left: 0,
                //     top: 0,
                //     width: "100%",
                //     height: "100%",
                //     zIndex: "999",
                //     backgroundColor: "rgba(0,0,0,1)",
                // }}
                >
                    This site uses cookies ...{" "}
                    <a href="/privacypolicy" target="_blank" rel="noopener noreferrer" className="underline lg:mr-2">
                        Privacy Policy
                    </a>
                </CookieConsent>

                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                            if (navigator && navigator.serviceWorker && navigator.serviceWorker.getRegistrations) {
                                navigator.serviceWorker.getRegistrations().then((registrations) => {
                                for (let registration of registrations) {
                                    registration.unregister()
                                }
                            })
                            }
                        `
                    }} />
                <SEO
                    keywords={[`sparkrise`, `charity`, `uso`, `twainmania`, `twain`, `mania`, `change`, `top gun`, `spark`, `rise`, `sparks`, `change`, `top`, `gun`, `val`, `kilmer`, `iceman`, `tom`, `cruise`, `tom cruise`, `maverick`, `viper`, `goose`, `sundown`, `stinger`, `jester`, `slider`, `hollywood`, `cougar`, `wolfman`, `merlin`, `charlie`, `chipper`, `display`, `beem`, `nft`, `crypto`]}
                    title="Top Gun Celebration - Thank You!"
                    description="The evening begins with Val’s Potluck Movie Night. Please give what you can by clicking on the donate button. Proceeds will benefit the USO and Val Kilmer’s TwainMania Foundation."
                    meta={[
                        {
                            name: "image",
                            content: "https://www.sparkrise.com/c/top-gun-celebration-2021/John-Becerra-Val-Kilmer-Brad-Koepenick.jpeg"
                        },
                        {
                            property: "og:image",
                            content: "https://www.sparkrise.com/c/top-gun-celebration-2021/John-Becerra-Val-Kilmer-Brad-Koepenick.jpeg"
                        },
                    ]}
                />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="194x194" href="/favicon-194x194.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#e2662a" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
                <meta name="theme-color" content="#ffffff" />

                <section className="flex justify-center px-6 lg:px-12 bg-light-purple py-14">
                    <div className="flex flex-col items-center justify-center w-full xl:container lg:flex-row">
                        <div className="w-full mx-auto mb-12 lg:w-2/12 lg:order-2 lg:mb-0">
                            <div className="py-4 pl-1 pr-8 mb-12 ribbon-right bg-purple lg:hidden">
                                <p className="z-10 mb-0 text-base text-white">
                                    THANK YOU
                                </p>
                            </div>
                                <br />
                                <div className="w-full text-align-right">
                                    <h5 className="mb-4 c-h5 text-purple">Sponsors</h5>
                                    <p className="text-xs font-bold">
                                        Please visit our sponsors.<br /><br />
                                        Each click or registration makes a donation!
                                    </p>
                                    <div className="flex flex-row flex-nowrap lg:flex-col">
                                        <div className="w-1/2 mb-4 lg:w-full">
                                            <Link to="https://www.sparkrise.com/go/dakkota-topgun" target="_blank">
                                                <StaticImage
                                                    src="../../../images/Dakkota_Logo.svg"
                                                    alt="dakkota logo"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="flex flex-row flex-nowrap lg:flex-col">
                                        <div className="w-1/2 mb-4 lg:w-full">
                                            <Link to="https://www.sparkrise.com/go/wahoos-topgun" target="_blank">
                                                <StaticImage
                                                    src="../../../images/WahoosLogo_500.png"
                                                    alt="wahoos logo"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="flex flex-row flex-nowrap lg:flex-col">
                                        <div className="w-1/2 mb-4 lg:w-full">
                                            <Link to="https://www.sparkrise.com/go/display-topgun" target="_blank">
                                                <StaticImage
                                                    src="../../../images/display-logo.png"
                                                    alt="display logo"
                                                />
                                                <StaticImage
                                                    src="../../../images/SparkRise Display QR Code.jpg"
                                                    alt="display qr code"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="w-full lg:w-10/12 lg:order-1 lg:mr-8">
                            <br/><br/>
                            <div className="hidden py-4 pl-1 pr-8 mb-12 ribbon-right bg-purple lg:block">
                                <p className="z-10 mb-0 text-2xl text-white">
                                    THANK YOU
                                </p>
                            </div>
                            <center>
                                <div >
                                    <Img
                                        fluid={imageData.childImageSharp.fluid}
                                        className="h-auto w-1/5 object-fill object-center -z-10"
                                        style={{ position: "relative" }}
                                    />
                                    <br />
                                </div>
                                <h1 className="mb-8 c-h3 text-black font-bold uppercase">
                                    Val’s Potluck Movie Night
                                </h1>
                                <div>
                                    Help support the USO and TwainMania Foundation
                                    <br />
                                </div>
                                <br />
                                <div>
                                    <a href="https://www.sparkrise.com/go/topgun-pledge" target="_blank" rel="noreferrer" className="mb-4 c-button1">
                                        Donate Now
                                    </a>
                                </div>
                                <br />
                                <div className="text-left font-bold">
                                    About the USO: <br />
                                </div>
                                <p className="text-left ">
                                    The USO strengthens America’s military service members by keeping them connected to family, home and country, throughout their service to the nation. <br />
                                </p>
                                <div className="text-left font-bold">
                                    About TwainMania Foundation: <br />
                                </div>
                                <p className="text-left ">
                                    TwainMania, an organization founded by Val Kilmer, is inspired by the writings and philosophy of Mark Twain. It’s a unique program for students that teaches empathy and critical thinking to pose the question, “what does it mean to be a real American?” The nonprofit organization also helps fund other theater arts programs that support students in exploring the deeper meaning of life. <br />
                                </p>
                                <html
                                    dangerouslySetInnerHTML={{
                                        __html: `
                                        <!-- Begin Mailchimp Signup Form -->
                                        <style type="text/css">
                                            #mc_embed_signup{background:#faf8ff; clear:left; font:14px Helvetica,Arial,sans-serif; width:100%;}
                                            /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                                            We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
                                            /* MailChimp Form Embed Code - Horizontal Super Slim - 12/16/2015 v10.7
                                            Adapted from: http://blog.heyimcat.com/universal-signup-form/ */

                                            #mc_embed_signup form {text-align:center; padding:10px 0 10px 0;}
                                            .mc-field-group { display: inline-block; } /* positions input field horizontally */
                                            #mc_embed_signup input.email {font-family:"Open Sans","Helvetica Neue",Arial,Helvetica,Verdana,sans-serif; font-size: 15px; border: 1px solid #ABB0B2;  -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; color: #343434; background-color: #fff; box-sizing:border-box; height:32px; padding: 0px 0.4em; display: inline-block; margin: 0; width:350px; vertical-align:top;}
                                            #mc_embed_signup label {display:block; font-size:16px; padding-bottom:10px; font-weight:bold;}
                                            #mc_embed_signup .clear {display: inline-block;} /* positions button horizontally in line with input */
                                            #mc_embed_signup .button {font-size: 13px; border: none; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; letter-spacing: .03em; color: #fff; background-color: #aaa; box-sizing:border-box; height:32px; line-height:32px; padding:0 18px; display: inline-block; margin: 0; transition: all 0.23s ease-in-out 0s;}
                                            #mc_embed_signup .button:hover {background-color:#777; cursor:pointer;}
                                            #mc_embed_signup div#mce-responses {float:left; top:-1.4em; padding:0em .5em 0em .5em; overflow:hidden; width:90%;margin: 0 5%; clear: both;}
                                            #mc_embed_signup div.response {margin:1em 0; padding:1em .5em .5em 0; font-weight:bold; float:left; top:-1.5em; z-index:1; width:80%;}
                                            #mc_embed_signup #mce-error-response {display:none;}
                                            #mc_embed_signup #mce-success-response {color:#529214; display:none;}
                                            #mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}
                                            @media (max-width: 768px) {
                                                #mc_embed_signup input.email {width:100%; margin-bottom:5px;}
                                                #mc_embed_signup .clear {display: block; width: 100% }
                                                #mc_embed_signup .button {width: 100%; margin:0; }
                                            }
                                        </style>
                                        <div id="mc_embed_signup">
                                        <form action="https://sparkrise.us5.list-manage.com/subscribe/post?u=c69cb9a17ba4e42a9cc102f2d&amp;id=a98861fd86" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                            <div id="mc_embed_signup_scroll">
                                            <label for="mce-EMAIL">Get notified of future events and when event recordings are available</label>
                                            <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>
                                            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_c69cb9a17ba4e42a9cc102f2d_a98861fd86" tabindex="-1" value=""></div>
                                            <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                                            </div>
                                        </form>
                                        </div>
                                        <!--End mc_embed_signup-->
                                        `
                                    }} />
                                <div className="flex flex-col items-start justify-center lg:flex-row">
                                    Learn about our&nbsp;<Link to="https://www.sparkrise.com/go/wefunder" target="_blank" rel="noreferrer" className="underline lg:mr-2">WeFunder</Link>{" "}campaign
                                </div>
                            </center>
                        </div>
                    </div>
                </section>

                <body className="w-full px-6 py-12 mx-auto bg-white xl:w-full xl:container">
                    <footer className="container flex flex-col justify-center items-center lg:flex-row w-full"
                        aria-label="main footer"
                        role="contentinfo">
                        <Link to="https://www.sparkrise.com/" className="mr-0 lg:mr-4">
                            <Logo style={{ height: "68px", width: "218px" }} />
                        </Link>
                        <p className="text-center flex flex-col md:flex-row">
                            <table className="center">
                                <tr>
                                    Copyright © {new Date().getFullYear()} SparkRaiser, Inc. All Rights Reserved.
                                </tr>
                                <tr>
                                    <Link to="https://www.sparkrise.com/disclosurestatements" className="underline lg:mr-2">
                                        Disclosures
                                    </Link>{" "}
                                    <span className="md:inline px-2"> | </span>
                                    <Link to="https://www.sparkrise.com/termsofservice" className="underline lg:mr-2">
                                        Terms
                                    </Link>{" "}
                                    <span className="md:inline px-2"> | </span>
                                    <Link to="https://www.sparkrise.com/privacypolicy" className="underline lg:mr-2">
                                        Privacy
                                    </Link>
                                </tr>
                            </table>
                        </p>
                    </footer>
                </body>
            </main>
        </div>
    );
};

export default ActionPage;
